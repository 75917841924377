import React from "react"
const Footer = () => {
  return (
    <footer className="footer">
      <a
        className="footer__link"
        title="Connect with me on LinkedIn"
        rel="external"
        href="https://www.linkedin.com/in/danton-nguyen-9442a925/"
      >
        <i className="fab fa-linkedin fa-2x" />
      </a>
      <a
        className="footer__link"
        title="See my Github"
        rel="external"
        href="https://github.com/nuwen"
      >
        <i className="fab fa-github-square fa-2x" />
      </a>
      <a
        className="footer__link"
        title="Checkout my Instagram"
        rel="external"
        href="https://www.instagram.com/dantonnuwen/"
      >
        <i className="fab fa-instagram fa-2x" />
      </a>
      <a
        className="footer__link"
        title="Contact me via e-mail"
        rel="nofollow"
        href="mailto:dantonnguyen@hotmail.com"
      >
        <i className="fas fa-envelope-square fa-2x" />
      </a>
      {/* <a className="footer__link" href="/">
          <i className="fab fa-twitter-square fa-2x" />
        </a> */}
    </footer>
  )
}

export default Footer
