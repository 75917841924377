import React from "react"
import Menu from "./Menu"
import Footer from "./Footer"

import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"
import "../styles/css/main.min.css"

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  function handleKey() {
    let t = new Date()
    t = Math.random() * t.getTime()
    return t
  }

  return (
    <>
      <div className="container">
        <div className="contentBox">
          <Menu handleKey={handleKey} />
          <div className="content">
            <main>{children}</main>
          </div>
          <Footer />
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
