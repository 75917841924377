import React from "react"
import ReactHtmlParser from "react-html-parser"
import { Link } from "@reach/router"

class Menu extends React.Component {
  render() {
    let menuItems = [
      {
        menu: "danton nguyen",
        icon: "danton nguyen",
        link: "/",
        label: "Go back to homepage",
      },
      {
        menu: "work",
        icon: '<i class="fas fa-code"></i>',
        link: "/work",
        label: "See some of Danton's past employers",
      },
      {
        menu: "skills",
        icon: '<i class="fas fa-mug-hot"></i>',
        link: "/skills-and-experience",
        label: "View a list of Danton's tech experience",
      },
      {
        menu: "about",
        icon: '<i class="fas fa-user"></i>',
        link: "/about",
        label: "A brief intro about Danton Nguyen",
      },
      // {
      //   menu: "contact",
      //   icon: "contact",
      //   link: "/contact"
      // }
    ]

    return (
      <nav className="menu">
        <ul className="menu__items">
          {menuItems.map(({ menu, link, icon, label }) => (
            <li
              className={`menu__item ${menu} ${
                menu === "danton nguyen" ? "home" : ""
              }`}
              key={this.props.handleKey()}
            >
              <Link
                to={link}
                menu={menu}
                aria-label={label}
                getProps={({ isCurrent }) => {
                  if (isCurrent) {
                    return {
                      className: `menu__link ${menu} ${
                        menu === "danton nguyen" ? " home " : ""
                      } active active--background`,
                    }
                  } else {
                    return {
                      className: `menu__link ${menu} ${
                        menu === "danton nguyen" ? " home " : ""
                      }`,
                    }
                  }
                }}
              >
                <span className="menu__link--large-only ">{menu}</span>
                {icon ? (
                  <span className="menu__link--mobile-only ">
                    {ReactHtmlParser(icon)}
                  </span>
                ) : (
                  ""
                )}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    )
  }
}

export default Menu
